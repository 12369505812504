<!--
 * @Description:
 * @Author: max
 * @Date: 2020-12-21 14:16:24
 * @LastEditTime: 2021-12-31 14:31:46
 * @LastEditors: max
 * @Reference:
-->
<template>
  <div id="app" :style="{ height: deviceHeight + 'px' }">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'app',
  data () {
    return {
      deviceHeight: 0
    }
  },

  mounted () {
    this.deviceHeight = document.documentElement.clientHeight
  }
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
#app {
  // font-family: 'alipuhui';
  // background-color: #fefeec;
  background: url(./assets/bg.jpg) no-repeat;
  overflow: hidden;
  background-size: 100% 100%;
  height: 100%;
}
</style>
